<template>
  <div>

    <div v-if="tenant.id === 'truenorth'">
      <h1>Never Stop Exploring!</h1>
      <p>
        How It Works:
      </p>
      <ul class="mt-n2 mb-4">
        <li>Select Event &amp; Register: Choose a challenge that best fits your skill and activity level</li>
        <li>Choose Activity &amp; Course: Run, Walk, Crawl - Choose any self propelled activity and complete the challenge from anywhere</li>
        <li>Cross The Finishline: Complete the challenge distance and cross your finishline</li>
      </ul>
      <p>
        <v-btn color="primary" href="/get" target="_blank">Download app</v-btn>
      </p>
        
      <h3>Powered by FitMap - Events and Challenges</h3>
      <p>
        FitMap is the most powerful platform to host endurance sports events and challenges. We offer integration with most popular fitness trackers, various challenge modes and numerous powerful features to create engaging in-person, hybrid, and virtual experiences! <a href="https://fitmap.app">Learn how »</a>
      </p>
      <v-btn x-large color="primary" href="https://truenorthchallenges.com/pages/contact-us" target="_blank">Contact Us</v-btn>
    </div>
    <div v-else>
      <h2 class="subtitle">Our mission</h2>
      <p>
              Our mission is to encourage everyone to be active and enjoy the great outdoors. 
              Our platform offers a solution to endurance sport events and challenges worldwide. 
              Whether it's an in-person, hybrid, or virtual event.
              Whether it's for a sport event, a corporate wellness program, or to reach a fundraising goal. 
              We are here to help you succeed.

        <!-- We started as a platform to host virtual challenges during the Covid pandemic. 
        Over time our platform has been optimized to host engaging events of all sorts. Whether your event is in-person, hybrid, or virtual, our platform has the features to create a connected and engaging experience for it! -->
      </p>
      <p>
        Our focus is to create the most fun and engaging experience for your participants. We allow them to keep using the fitness tracker they already use and love. For those users without a fitness tracker we have an easy to use manual entry tool.
      </p>

      <blockquote class="mb-4">"Make it easier and more fun to be active, and keep it up!"</blockquote>
      <p>We believe we can make it easier and more fun for anyone to live an active lifestyle. We use gamification techniques to motivate. We reward every breath and every drop of sweat. We set goals and help reach them. We encourage to keep going!</p>

      <h2 class="subtitle">Who we are</h2>
      <p>
        We were the inventors of the first Dutch running app and platform more than 10 years ago.
      </p>
      <p>
        Our team has over 25 years of combined experience in the sport technology industry. We have worked with the largest events world-wide (Boston Marathon, Hong Kong Marathon, and many others) providing timing, results, custom apps, and digital services for the most demanding events to deliver a top-notch experience to their participants. 
      </p>
      <p>
        {{tenant.name}} is proudly produced and maintained by Noctilucent BV. We also offer <a href="https://corporatefitness.app" target="_blank">CorporateFitness.app</a> for workplace challenges.
      </p>
      <p>
        We'd love to have your feedback on our website, so feel free to drop us a line.
      </p>

      <p>
        <v-btn color="primary" to="/contact">Contact Us</v-btn>
      </p>
    </div>

  </div>
</template>
<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "About",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>